export const environment = {
  production: false,
  ambiente: "dev",
  account: "adl",
  profile: "avv",
  channel: "CDT_CHANNELS",
  urlAssets: "https://d2h18c2x33mshi.cloudfront.net",
  baseUrl: "https://rb-pasivo-dev-api-int-avvillas.avaldigitallabs.com",
  appSyncConfig: {
    endpoint:
      "https://fvv7e5upjbaavn3glu2swlem6y.appsync-api.us-east-2.amazonaws.com/graphql",
    region: "us-east-2",
    type: "API_KEY",
    apiKey: "da2-i5unwa4xnbgxzi2oiunjhvnj7y",
  },
  SDKconfigcatKey:
    "configcat-sdk-1/msfcCERdNkKIFkIDUqaEOg/K1Neb4h30UqwkvDJCdFcvQ",
};
