import { enableProdMode, NgZone } from "@angular/core";

import { Router, NavigationStart } from "@angular/router";

import { singleSpaAngular } from "single-spa-angular";

import { environment } from "./environments/environment";
import { singleSpaPropsSubject } from "./single-spa/single-spa-props";

// tslint:disable-next-line: no-implicit-dependencies
import "node_modules/@avaldigitallabs/bavv-commons-lib-design-system/dist/bavv-input/elements.js";
// tslint:disable-next-line: no-implicit-dependencies
import "node_modules/@avaldigitallabs/bavv-commons-lib-design-system/dist/bavv-dropdown/elements.js";
// tslint:disable-next-line: no-implicit-dependencies
import "node_modules/@avaldigitallabs/bavv-commons-lib-design-system/dist/bavv-modal/elements.js";
// tslint:disable-next-line: no-implicit-dependencies
import "node_modules/@avaldigitallabs/bavv-commons-lib-design-system/dist/bavv-otp-input/elements.js";
// tslint:disable-next-line: no-implicit-dependencies
import "@avaldigitallabs/bavv-commons-lib-design-system/dist/bavv-loader/elements.js";

import "@avaldigitallabs/bavv-commons-lib-design-system/dist/bavv-slide-toggle/elements.js";
import "@avaldigitallabs/bavv-commons-lib-design-system/dist/bavv-loader-flickering/elements.js";

import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";

if (environment.production) {
  enableProdMode();
}

const lifecycles = singleSpaAngular({
  bootstrapFunction: (singleSpaProps) => {
    singleSpaPropsSubject.next(singleSpaProps);
    return bootstrapApplication(AppComponent, appConfig);
  },
  template: "<app-cda-integration />",
  Router,
  NavigationStart,
  NgZone,
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
