import { navigateToUrl } from 'single-spa';

export const validateHistoryState = () => {
  window.onpopstate = (e) => {
    if ('singleSpaTrigger' in e) {
      return;
    } else {
      history.replaceState(null, '', '/bancadigital');
    }
  };
  if (
    (window.performance.getEntries()[0] as PerformanceNavigationTiming).type ===
    'reload'
  ) {
    history.replaceState(null, '', '/bancadigital');
  }
};
